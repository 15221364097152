<template>
  <div class="fangwenliang content">
    <div class="contentbox">
      <vue-scroll :ops="ops" :style="{ width: '100%' }">
        <TableQuery
          :formList="formList"
          :formInline="formInline"
          :customArrKey="customArrKey"
          :btnList="btnList"
          :property="queryProperty"
          @btnClickFun="btnClickFun"
          @moveQueryFun="queryBackFun"
          @formDataFun="formDataFun"
          @radioChange="radioChange"
        >
        </TableQuery>
        <div class="p-t-10"></div>
        <el-card>
          <div slot="header" class="clearfix cardTitle">
            <span class="titleSpan"></span>
            视频访问量排名
          </div>
          <div class="section" style="padding-bottom: 20px">
            <div v-show="paiming" style="width: 100%; position: relative">
              <div
                ref="chart1"
                class="chart"
                id="chart1"
                style="height: 320px; width: 100%"
              ></div>
            </div>
            <div v-show="!paiming" class="imgBox">
              <img
                src="../../assets/img/noData.png"
                alt=""
                style="display: block; margin: 0 auto"
              />
            </div>
          </div>
        </el-card>
      </vue-scroll>
    </div>
  </div>
</template>
<script>
import configBasics from "@/utils/configBasics";
import TableQuery from "@/common/module/TableQuery";
export default {
  name: "fangwenliang",
  components: {
    TableQuery
  },
  data() {
    return {
      ops: configBasics.vueScrollOps,
      formList: [
        {
          name: "type",
          width: "500px",
          showhide: true,
          multiple: false,
          label: "类型",
          type: "radios",
          clearable: false,
          radiochildren: [
            { labelname: "任意时间", value: "any" },
            { labelname: "本周", value: "week" },
            { labelname: "本月", value: "month" },
            { labelname: "最近一个月", value: "recently" }
          ]
        },
        {
          clearable: true,
          datetype: "date",
          endplaceholder: "请选择结束值",
          format1: "yyyy-MM-dd",
          format: "yyyy-MM-dd",
          showhide: true,
          label: "时间",
          valueformat1: "yyyy-MM-dd",
          type: "DatePickerScope",
          startname: "starttime",
          startplaceholder: "请选择起始值",
          characteristic: "26196aa2-8223-4271-9f6c-4718dwsdssdsd",
          valueformat: "yyyy-MM-dd",
          pickeroptionsend:
            "pickerOptionsEnd26196aa2-8223-4271-9f6c-4718dwsdssdsd",
          name: "choosetime",
          width: "450px",
          endname: "endtime",
          disabled: false,
          control: true
        },
        {
          clearable: true,
          name: "videotypecode",
          showhide: true,
          placeholder: "请选择视频类型",
          label: "视频类型",
          type: "treeselect",
          treedata: []
        }
      ],
      formInline: {
        type: "month",
        choosetime: ["", ""],
        videotypecode: ""
      },
      customArrKey: ["choosetime"],
      btnList: [
        {
          name: "searchButton",
          icon: "icon-tianjia",
          label: "统计",
          type: "searchButton"
        }
      ],
      queryProperty: {
        labelWidth: "120px",
        typeName: "fangwenliang",
        moveLabelWidth: "120px"
      },
      paiming: true,
      option1: {
        color: configBasics.echartsColor,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            return (
              params[0].name +
              "<br/>" +
              params[0].marker +
              "访问量：" +
              params[0].value
            );
          }
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: []
        },
        yAxis: {
          type: "value",
          name: "访问量",
          axisLine: {
            show: true
          }
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 30
          }
        ]
      },
      optionData: null
    };
  },
  created() {
    this.findByDomainid();
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    radioChange(val, name) {
      // console.log(name, val);
      if (name == "type") {
        if (val == "any") {
          this.formList[1].showhide = true;
        } else {
          this.formList[1].showhide = false;
        }
      }
    },
    changeTreeNew(val) {
      let arr = [];
      if (val.length !== 0) {
        val.map(item => {
          let obj = {};
          obj.id = item.typeCode;
          obj.label = item.text;
          if (item.nodes && item.nodes.length >= 1) {
            obj.children = this.changeTreeNew(item.nodes);
          }
          arr.push(obj);
        });
      }
      return arr;
    },
    findByDomainid() {
      this.$api("videoManage.getVideoTypeTree")
        .invoke()
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            let dataList = data.data;
            if (dataList) {
              let treedata = [];
              if (dataList.length) {
                dataList.map((e, i) => {
                  treedata.push({
                    id: this.changeTreeNew(dataList)[i].id,
                    label: this.changeTreeNew(dataList)[i].label,
                    children: this.changeTreeNew(dataList)[i].children
                  });
                });
              } else {
                treedata = [];
              }
              treedata.unshift({
                id: "",
                label: "全部"
              });
              this.formInline.videotypecode = treedata[0].id;
              const len = this.formList.length;
              for (let i = 0; i < len; i++) {
                if (this.formList[i].name === "videotypecode") {
                  this.formList[i].treedata = treedata;
                }
              }
            }
          } else {
          }
        })
        .catch(() => {});
    },
    initEchart() {},
    getTableList() {
      let that = this;
      let starttime = "";
      let endtime = "";
      if (this.formInline.type == "week") {
        starttime = this.$moment().startOf("week").format("YYYY-MM-DD");
        endtime = this.$moment().endOf("week").format("YYYY-MM-DD");
      } else if (this.formInline.type == "month") {
        starttime = this.$moment().startOf("month").format("YYYY-MM-DD");
        endtime = this.$moment().endOf("month").format("YYYY-MM-DD");
      } else if (this.formInline.type == "recently") {
        starttime = this.$moment(new Date())
          .subtract("day", 30)
          .format("YYYY-MM-DD");
        endtime = this.$moment().format("YYYY-MM-DD");
      }
      let obj = {
        starttime: starttime,
        endtime: endtime,
        videotypecode: this.formInline.videotypecode
      };
      this.$api("tongjifenxi.statisticVideoUser")
        .invoke(obj)
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            if (data.data.length > 0) {
              this.paiming = true;
              let xAxis = [];
              let series = [];
              data.data.map(e => {
                series.push(e.chartcount);
                xAxis.push(e.name);
              });
              that.option1.xAxis.data = xAxis;
              that.option1.series[0].data = series;
              that.optionData = that.$echarts.init(that.$refs.chart1);
              that.optionData.clear();
              that.optionData.setOption(that.option1);
            } else {
              this.paiming = false;
              return false;
            }
          } else {
          }
        })
        .catch(() => {});
    },
    // 按钮点击事件
    btnClickFun(item) {
      if (item.name === "searchButton") {
        // 查询
        this.getTableList();
      }
    },
    // 回车查询
    queryBackFun(formInline) {
      this.formInline = formInline;
      // 查询
      this.getTableList();
    },
    // 查询标签点击事件
    formDataFun(formInline) {
      this.formInline = formInline;
      this.getTableList();
    }
  }
};
</script>
<style lang="less" scoped></style>
